<template>
  <div class="ml-1">
    <div class="mb-4">
      <img
        :src="ErboLogo"
        alt="aw"
      >
    </div>
    <h4 class="text-center font-weight-bolder mb-1">
      {{ $t(transferOrderInformation.order_id) }}{{ `—${transferOrderInformation.origin_warehouse} to ${transferOrderInformation.receiving_warehouse.label}` }}
    </h4>
    <quote-status-bar
      :item="quote"
      class="mb-4"
    />
    <transfer-schedule :is-print="true" />
    <assets-table :is-print="true" />
  </div>
</template>
<script>
import ErboLogo from '@/assets/images/ico/erbo-logo.svg'
import TransferSchedule from './TransferSchedule.vue'
import AssetsTable from './AssetsTable.vue'
import QuoteStatusBar from '../../create/common/QuoteStatusBar.vue'
import config from '../../../permanentTransferScheduled/permanentTransferByInventoryQty/config'

export default {
  components: { AssetsTable, TransferSchedule, QuoteStatusBar },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quoteTransferOrder
    },
    transferOrderInformation() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    return {
      MODULE_NAME,
      ErboLogo,
    }
  },
}

</script>
