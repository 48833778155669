<template>
  <div>
    <permanent-transfer-skeleton v-if="loading" />
    <div v-else>
      <portal to="body-top">
        <h4 class="text-center font-weight-bolder mb-1">
          {{ $t(transferOrderInformation.order_id) }}{{ `—${transferOrderInformation.shipping_warehouse.label} to ${transferOrderInformation.receiving_warehouse.label}` }}
        </h4>
      </portal>
      <quote-status-bar
        :item="quote"
        class="ml-1"
      />
      <transfer-schedule
        class="mt-1"
        :is-loading="loading"
      />
      <assets-table
        class="mt-1"
        :is-change="isChange"
        :is-print="disabledButton"
        @isClickAddButton="addHandler"
        @isAddItem="isAddProduct"
      />
      <b-modal
        id="scheduled-transfer-order"
        centered
        ok-variant="success"
        hide-header
        hide-footer
        size="md"
      >
        <div
          class="text-center"
        >
          <span>
            <h4 class="d-inline font-weight-bolder">
              {{ $t('Remove Items') }}
            </h4>
          </span>
          <div>
            <span
              v-for="field in transferOrderInformation.assets"
              :key="field.id"
              class="d-flex align-items-center pl-1"
            >
              <b-form-checkbox
                :id="String(field.id)"
                v-model="field['isChecked']"
                style="margin-right: 5px"
                :disabled="!$can(ACCESS_TRANSFER_SCHEDULE_ASSET.action, ACCESS_TRANSFER_SCHEDULE_ASSET.subject)"
              />
              <span
                class="d-flex align-items-center justify-content-between"
                style="width: 95%; margin: 8px auto"
              >
                <span>Asset ID {{ $t(field.asset_id) }}</span>
                <span>{{ $t(field.name) }}</span>
              </span>
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between mt-1">
            <span>
              <b-button
                variant="outline-primary"
                class="font-medium-1 font-weight-bolder"
                @click="goBack"
              >
                {{ $t('Go Back') }}
              </b-button>
            </span>
            <span>
              <b-button
                variant="success"
                class="font-medium-1 font-weight-bolder"
                @click="releaseAssets"
              >
                {{ $t('Remove') }}
              </b-button>
            </span>
          </div>
        </div>
      </b-modal>
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <b-button
              v-if="!isFormChanged || !isAddItems"
              class="font-medium-1 font-weight-bolder"
              variant="outline-primary"
              style="width: 170px; height: 40px;"
              @click="backToListBtn"
            >
              {{ $t('Back to List') }}
            </b-button>
            <b-button
              v-else
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="cancelBtn"
            >
              <feather-icon
                icon="LCancelIcon"
                size="16"
              />
              {{ $t('Cancel') }}
            </b-button>
          </div>
          <div class="d-flex align-items-center">
            <b-button
              :disabled="isLoadingButton || disabledButton || (transferOrderInformation.items < 1)"
              variant="danger"
              class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder mr-1"
              style="width: 170px; height: 40px;"
              @click="submitVoid"
            >
              <feather-icon
                icon="LNoIcon"
                size="18"
                style="margin-right: 3px"
              />
              {{ $t('Void') }}
            </b-button>
            <b-button
              v-if="!isAddItems"
              variant="success"
              :disabled="isLoadingButton || disabledButton || (transferOrderInformation.items < 1)"
              class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder mr-1"
              style="width: 170px; height: 40px;"
              @click="printScheduledPageTemplate"
            >
              <feather-icon
                icon="LPrintIcon"
                size="18"
                style="margin-right: 3px"
              />
              <Printer>
                <div
                  id="scheduledPage"
                  hidden
                >
                  <scheduled-page-template />
                </div>
              </Printer>
              {{ $t('Print') }}
            </b-button>
            <b-button
              v-if="!isAddItems"
              :disabled="isLoadingButton || disabledButton || (transferOrderInformation.items < 1)"
              variant="success"
              class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder"
              style="width: 170px; height: 40px;"
              @click="releaseItems"
            >
              {{ $t('Remove Items') }}
            </b-button>
            <b-button
              v-else
              :disabled="disabledButton"
              variant="success"
              class="d-flex align-items-center justify-content-center font-medium-1 font-weight-bolder"
              style="width: 170px; height: 40px;"
              @click="submit"
            >
              {{ $t('Add Items') }}
            </b-button>
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>
<script>
import { BButton, BFormCheckbox } from 'bootstrap-vue'
import Printer from '@/views/components/Printer/Printer.vue'
import PermanentTransferSkeleton
from '@/views/main/orders/view/transfer/components/permanentTransferScheduled/permanentTransferByInventoryQty/PermanentTransferSkeleton.vue'
import ScheduledPageTemplate
from '../../components/permanentTransferScheduled/permanentTransferByInventoryQty/ScheduledPageTemplate.vue'
import QuoteStatusBar from '../../components/create/common/QuoteStatusBar.vue'
import TransferSchedule from '../../components/permanentTransferScheduled/permanentTransferByInventoryQty/TransferSchedule.vue'
import AssetsTable from '../../components/permanentTransferScheduled/permanentTransferByInventoryQty/AssetsTable.vue'
import config from './config'

export default {
  components: {
    PermanentTransferSkeleton,
    ScheduledPageTemplate,
    BButton,
    BFormCheckbox,
    AssetsTable,
    TransferSchedule,
    QuoteStatusBar,
    Printer,
  },
  data() {
    return {
      isChange: false,
      isAddItems: false,
      loading: false,
      loadingFor: {
        isLoading: false,
        action: null,
      },
      isLoadingButton: false,
      filterAssests: [],
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quoteTransferOrder
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    transferOrderInformation() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
    transferOrderInformationClone() {
      return this.$store.state.cloneData.transferOrderInformationClone
    },
    isFormChanged() {
      return (JSON.stringify(this.transferOrderInformation) !== this.transferOrderInformationClone) || this.isChange
    },
    disabledButton() {
      return this.quote.state === 0 && this.quote.status === 5
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch(`${this.MODULE_NAME}/getTransferOrder`, { id: this.$route.params.id }).then(res => {
      const { data } = res
      let mappedAssets = []
      if (data && Array.isArray(data.assets)) {
        mappedAssets = data.assets.map(({
          id,
          type,
          sku,
          asset_id,
          name,
          purpose,
          request_by,
          requested_by,
          order_type,
          status,
          inventory_item,
        }) => ({
          id,
          type,
          sku,
          name,
          product_id: asset_id || id,
          asset_id: inventory_item.id,
          purpose,
          request_by,
          requested_by,
          order_type,
          status,
          inventory_item,
        }))
      }
      const scheduledTransferOrder = {
        id: data.id,
        comment: data.comment,
        method: data.method,
        order_id: data.order_id,
        receiving_warehouse: data.receiving_warehouse,
        shipping_warehouse: data.shipping_warehouse,
        scheduled_dispatch: new Date(data.scheduled_dispatch).toLocaleString('default', {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        }),
        transfer_time: data.transfer_time,
        origin_warehouse: this.warehouse.label,
        assets: mappedAssets,
        items: data.items,
        type: data.type,
      }
      this.$emit('refetchData')
      this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_SCHEDULED`, scheduledTransferOrder)
      this.$store.commit('cloneData/SET_TRANSFER_ORDER_SCHEDULED_INFORMATION_CLONE', scheduledTransferOrder)
      this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_QUOTE`, {
        state: data.state,
        status: data.status,
      })
      this.loading = false
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    isAddProduct(event) {
      this.isAddItems = event
    },
    printScheduledPageTemplate() {
      this.$htmlToPaper('scheduledPage', {
        autoClose: false,
      })
    },
    addHandler(event) {
      this.isChange = event
    },
    releaseAssets() {
      this.filterAssests = this.transferOrderInformation.assets.filter(item => item.isChecked === true).map(k => k.id)
      this.transferOrderInformation.assets = this.transferOrderInformation.assets.filter(item => item.isChecked !== true)
      if (this.filterAssests.length) {
        this.submitRelease()
      }
      // this.isAddItems = true
      // this.goBack()
    },
    goBack() {
      this.$bvModal.hide('scheduled-transfer-order')
    },
    releaseItems() {
      this.$bvModal.show('scheduled-transfer-order')
    },
    backToListBtn() {
      this.$router.push({ name: 'home-orders-transfer' })
    },
    cancelBtn() {
      this.isChange = false
      this.transferOrderInformation.assets = JSON.parse(this.transferOrderInformationClone.assets)
    },
    submit() {
      const mappedAssets = this.transferOrderInformation.assets.map(({
        id, purpose, product_id, amount,
      }) => {
        if (purpose) {
          return ({ id, product_id, amount: amount || 1 })
        }
        return ({ product_id: id, amount: amount || 1 })
      })
      const data = this.transferOrderInformation
      const { id } = this.transferOrderInformation
      const transferOrder = {
        comment: data.comment,
        products: mappedAssets,
      }
      this.sendNotification(this, { transferOrder, id }, `${this.MODULE_NAME}/updateTransferOrder`)
        .then(res => {
          const { data } = res.data
          this.transferOrderInformation.assets = data.assets
        }).finally(() => {
          this.isAddItems = false
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
        })
    },
    submitVoid() {
      this.isLoadingButton = true
      const { id } = this.transferOrderInformation
      const action = {
        _id: id,
        status: this.VOID_ACTION,
      }
      this.sendNotification(this, action, `${this.MODULE_NAME}/updateState`)
        .then(res => {
          const { data } = res.data
          this.$router.push({ name: 'home-orders-transfer', params: { id: data.id } })
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
          this.isLoadingButton = false
        })
    },
    submitRelease() {
      this.isLoadingButton = true
      const { id } = this.transferOrderInformation
      const action = {
        id,
        assets: {
          assets: this.filterAssests,
        },
      }
      this.sendNotification(this, action, `${this.MODULE_NAME}/release`)
        .then(res => {
          const { data } = res.data
          if (!this.transferOrderInformation.assets.length) {
            this.$router.push({ name: 'home-orders-transfer', params: { id: data.id } })
          } else {
            this.$router.go(this.$router.currentRoute)
          }
        }).finally(() => {
          this.loadingFor = {
            isLoading: false,
            action: null,
          }
          this.isLoadingButton = false
        })
    },
  },
  setup() {
    const { MODULE_NAME, VOID_ACTION, ACCESS_TRANSFER_SCHEDULE_ASSET } = config()

    return {
      MODULE_NAME,
      VOID_ACTION,
      ACCESS_TRANSFER_SCHEDULE_ASSET,
    }
  },
}
</script>
<style scoped>
.saveBtn {
  width: 150px;
  padding: 0 40px;
}
</style>
