var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white p-1 rounded-lg mt-1"},[_c('h1',{staticClass:"font-weight-bolder font-medium-5 pl-1"},[_vm._v(" "+_vm._s(_vm.$t('Asset(s)'))+" ")]),_c('l-table-list-collector',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.assetsTableColumns,"table-suggestion-configs":_vm.autoSuggestTableColumns,"is-searchable":_vm.isSearchable && _vm.isChange,"is-pagination":true,"is-footer":true,"can-existed-item":false,"fetched-data":_vm.transferOrderInformation.assets,"can-create":_vm.ACCESS_TRANSFER_SCHEDULE_ASSET,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getProduct")
    }},on:{"getCollectedList":_vm.getCollectedList},scopedSlots:_vm._u([{key:"pagination",fn:function(){return [_vm._v(" "+_vm._s()+" ")]},proxy:true},(!_vm.isPrint)?{key:"filter",fn:function(){return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LAddButtonIcon","size":"34"},on:{"click":_vm.addHandler}})]},proxy:true}:null,{key:"cell(type)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'order_type', '—'))+" ")]}},{key:"cell(sku)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"8px"}},[_c('state-of-asset',{attrs:{"data":item,"with-label":false}}),_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'sku', '—'))+" ")],1)]}},{key:"cell(asset_id)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'inventory_item.id', '—'))+" ")])]}},{key:"cell(purpose)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'purpose', '—'))+" ")])]}},{key:"cell(request_by)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'requested_by', '—'))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }