<template>
  <div class="bg-white p-1 rounded-lg mt-1">
    <h1
      class="font-weight-bolder font-medium-5 pl-1"
    >
      {{ $t('Asset(s)') }}
    </h1>
    <l-table-list-collector
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="assetsTableColumns"
      :table-suggestion-configs="autoSuggestTableColumns"
      :is-searchable="isSearchable && isChange"
      :is-pagination="true"
      :is-footer="true"
      :can-existed-item="false"
      :fetched-data="transferOrderInformation.assets"
      :can-create="ACCESS_TRANSFER_SCHEDULE_ASSET"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getProduct`
      }"
      @getCollectedList="getCollectedList"
    >
      <template #pagination>
        {{ }}
      </template>
      <template
        v-if="!isPrint"
        #filter
      >
        <feather-icon
          icon="LAddButtonIcon"
          class="cursor-pointer"
          size="34"
          @click="addHandler"
        />
      </template>
      <template #cell(type)="{data: {item}}">
        {{ getValueFromGivenObjectByKey(item, 'order_type', '—') }}
      </template>
      <template #cell(sku)="{data: {item}}">
        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <state-of-asset
            :data="item"
            :with-label="false"
          />
          {{ getValueFromGivenObjectByKey(item, 'sku', '—') }}
        </div>

      </template>
      <template #cell(asset_id)="{data: {item}}">
        <div
          class="pl-2"
        >
          <!--          <feather-icon-->
          <!--            icon="LTransferCalendarIcon"-->
          <!--            size="18"-->
          <!--            style="fill: #6D3B8F"-->
          <!--          />-->
          {{ getValueFromGivenObjectByKey(item, 'inventory_item.id', '—') }}
        </div>
      </template>
      <template #cell(purpose)="{data: {item}}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'purpose', '—') }}
        </div>
      </template>
      <template #cell(request_by)="{data: {item}}">
        <div class="pl-3">
          {{ getValueFromGivenObjectByKey(item, 'requested_by', '—') }}
        </div>
      </template>
    </l-table-list-collector>
  </div>
</template>
<script>
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import config from '@/views/main/orders/view/transfer/permanentTransferScheduled/permanentTransferByInventoryQty/config'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { LPickIcon } from '@core/components/feather-icon/customIcons'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'

export default {
  name: 'AssetsTable',
  components: { StateOfAsset, LTableListCollector },
  props: {
    isChange: {
      type: Boolean,
      default: false,
    },
    isPrint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSearchable: false,
    }
  },
  computed: {
    LPickIcon() {
      return LPickIcon
    },
    transferOrderInformation() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    addHandler() {
      this.isSearchable = true
      this.$emit('isClickAddButton', this.isSearchable)
    },
    getCollectedList() {
      this.$emit('isAddItem', this.isSearchable)
    },
  },
  setup() {
    const {
      MODULE_NAME, assetsTableColumns, autoSuggestTableColumns, ACCESS_TRANSFER_SCHEDULE_ASSET,
    } = config()

    return {
      MODULE_NAME,
      assetsTableColumns,
      autoSuggestTableColumns,
      ACCESS_TRANSFER_SCHEDULE_ASSET,
    }
  },
}
</script>
